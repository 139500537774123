import { useFocusRing } from '@react-aria/focus';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import NextLink from 'next/link';
import { Text } from '@/components/text';
import { BROWSE_ALL_ROUTE, getRouteForCategory } from '@/types/routes';
import {
  getDescriptionForScamCategory,
  getDisplayStringForScamCategory,
  ScamCategory,
} from '@/types/scam-categories';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Button } from '@/components';

const ROOT = makeRootClassName('HomeTopScamCategories');
const el = makeElementClassNameFactory(ROOT);

// helpers

type TopScamCardProps = StyleProps & {
  /**
   * The scam category.
   */
  category: ScamCategory;

  /**
   * The url to navigate to when the card is clicked.
   */
  href: string;

  /**
   * Total reports filed for this category.
   */
  totalReports: number;
};

const DEFAULT_PROPS = {} as const;

function TopScamCard(props: TopScamCardProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <NextLink href={p.href}>
      <div
        {...focusProps}
        className={clsx(
          el`category-card`,
          { 'is-focus-visible': isFocusVisible },
          p.className
        )}
      >
        <div className={el`category-header`}>
          <Text type="h4">{getDisplayStringForScamCategory(p.category)}</Text>
        </div>
        <div className={el`info`}>
          <Text className={el`description`} type="body-sm">
            {getDescriptionForScamCategory(p.category)}
          </Text>
          <Text type="h6" className={el`link`}>
            {`See ${p.totalReports} Reports`}
          </Text>
        </div>
      </div>
    </NextLink>
  );
}

// main

type ScamKindWithReportsCount = {
  kind: ScamCategory;
  reportsFiledCount: number;
};

export type TopCategoriesContributorsProps = StyleProps & {
  scamCategories: ScamKindWithReportsCount[] | null;
};

function TopScamCategories(
  props: TopCategoriesContributorsProps
): ReactElement {
  return (
    <section className={ROOT}>
      <div className={el`header`}>
        <Text type="h3" className={el`title`}>
          Top Scam Categories
        </Text>
        <div className={el`actions`}>
          <NextLink href={BROWSE_ALL_ROUTE}>
            <Button variant="secondary" className={el`browse-reports-button`}>
              View All
            </Button>
          </NextLink>
        </div>
      </div>      
      <div className={el`category-cards`}>
        {props.scamCategories &&
          props.scamCategories.map((category, index) => (
            <TopScamCard
              key={`${category}-${index}`}
              category={category.kind}
              totalReports={category.reportsFiledCount}
              href={getRouteForCategory(category.kind)}
            />
          ))}
      </div>
    </section>
  );
}

export default TopScamCategories;
