import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import { Icon, Text } from '@/components';

import { useAuthRequiredAction } from '@/hooks';
import { FILE_REPORT_ROUTE } from '@/types/routes';
import { createRingInfo } from '@/assets/icons';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '../../../../utils';

export type BCANoticeProps = StyleProps;

const ROOT = makeRootClassName('HomeBCANotice');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export default function BCANotice(props: BCANoticeProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { isLoggedIn, openAuthModal } = useAuthRequiredAction();
  const router = useRouter();

  const handleNavigateToFileReport = () => {
    if (
      !isLoggedIn &&
      !process.env.NEXT_PUBLIC_REQUIRE_AUTH_TO_REPORT_ENABLED
    ) {
      return openAuthModal();
    }
    router.push(FILE_REPORT_ROUTE);
  };

  return router.query.bca === 'true' ? (
    <section className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        <Icon content={createRingInfo} className={el`icon`} />
        <Text>
          <strong>
            Welcome! BitcoinAbuse and Chainabuse have joined forces.
          </strong>{' '}
          You can now{' '}
          <a className={el`reportlink`} onClick={handleNavigateToFileReport}>
            report crypto scams here
          </a>{' '}
          &raquo;
        </Text>
      </div>
    </section>
  ) : (
    <></>
  );
}
