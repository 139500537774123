import clsx from 'clsx';
import { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { ScamKindWithReportsCount } from '@/types/scam-categories';
import {
  getScamReportCardPropsFromReport,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { SortByOption } from '@/types/sort-by';
import { ScamReportCardProps } from '@/features/scam-report-card';
import { useResults } from '@/hooks';
import { getRouteForReport } from '@/types/routes';
import { ReportsSection } from '@/features/reports-section';
import { useScamCategories } from '../../hooks';
import TopScamCategories from '../top-scams/TopScamCategories';

type ScamCategories = ScamKindWithReportsCount[] | null;

export type RecentReportsTopScamCategoriesProps = StyleProps & {
  topScamCategories: ScamCategories;
};

const ROOT = makeRootClassName('RecentReportsTopScamCategories');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function RecentReportsTopScamCategories(
  props: RecentReportsTopScamCategoriesProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { push } = useRouter();

  const { results } = useResults({
    initialSort: SortByOption.NEWEST,
    resultsPerPage: 4,
    lookAhead: false,
  });

  const cardsData = results?.map(
    (result): ScamReportCardProps => ({
      ...getScamReportCardPropsFromReport(result),
      onClick: () => push(getRouteForReport(result.id)),
    })
  );

  return (
    <section className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        <div className={el`reports-section`}>
          <ReportsSection results={cardsData ?? []} title="Recent Reports" />
        </div>
        <div className={el`top-scam-categories`}>
          <TopScamCategories scamCategories={p.topScamCategories} />
        </div>
      </div>
    </section>
  );
}

function RecentReportsTopScamCategoriesContainer(): ReactElement {
  const { topScamCategoriesWithReports } = useScamCategories();

  return (
    <RecentReportsTopScamCategories
      topScamCategories={topScamCategoriesWithReports}
    />
  );
}

export default RecentReportsTopScamCategoriesContainer;

