import {
  ChainKind,
  ScamCategoryKind,
  useReportStatsQuery,
} from '@/generated/graphql';

export const useStats = (): {
  scamReports: number;
  scamCategories: number;
  supportedChains: number;
  reportedAddresses: number;
  reportedUrls: number;
} => {
  const { data } = useReportStatsQuery();
  const scamReports = data?.reportStats.reportsCount ?? 0;
  const scamCategories = Object.keys(ScamCategoryKind).length;
  const supportedChains = Object.keys(ChainKind).length;
  const reportedAddresses = data?.reportStats.reportedAddressesCount ?? 0;
  const reportedUrls = data?.reportStats.reportedUrlsCount ?? 0;

  return {
    scamReports,
    scamCategories,
    supportedChains,
    reportedAddresses,
    reportedUrls,
  };
};
