import { ReactElement } from 'react';
import { makeElementClassNameFactory, makeRootClassName } from '../../utils';
import {
  Banner,
  BCANotice,
  WhyReportOnChainabuse,
  PrivacySafetyGuaranteed,
  RecentReportsTopScamCategories,
  StatsSection,
  TopContributors,
} from './components';

const ROOT = makeRootClassName('HomePage');
const el = makeElementClassNameFactory(ROOT);

export default function HomePage(): ReactElement {
  return (
    <div className={ROOT}>
      <Banner />
      <BCANotice />
      <WhyReportOnChainabuse />
      <PrivacySafetyGuaranteed />
      <RecentReportsTopScamCategories />
      <StatsSection />
      <TopContributors />
    </div>
  );
}
