import { ApolloError } from '@apollo/client';
import { subDays } from 'date-fns';
import { useTopUsersQuery } from '@/generated/graphql';
import { PublicUserData } from '@/types/PublicUserData';

export enum SinceOptions {
  LAST_7_DAYS = 'last-7-days',
  LAST_30_DAYS = 'last-30-days',
  ALL_TIME = 'all-time',
}
type UseTopUserOptions = {
  // add config options here
  since: SinceOptions;

  /*
   * The number of top users to fetch
   */
  topUsersCount: number;
};

type UseTopUserState = {
  // add any state the hook provides
  isLoading: boolean;
  topUsers: PublicUserData[];
  error?: ApolloError;
};

type UseTopUserActions = {
  // add any actions the hook provides
};

const since7Days = subDays(new Date(), 7).toISOString();
const since30Days = subDays(new Date(), 30).toISOString();

export type UseTopUserValue = UseTopUserState & UseTopUserActions;

const useTopUsers = (props: UseTopUserOptions): UseTopUserValue => {
  let date;

  if (props.since === SinceOptions.LAST_7_DAYS) {
    date = since7Days;
  } else if (props.since === SinceOptions.LAST_30_DAYS) {
    date = since30Days;
  } else if (props.since === SinceOptions.ALL_TIME) {
    date = undefined;
  }

  const { data, loading, error } = useTopUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        top: props.topUsersCount,
        createdAtFilter: {
          since: date,
        },
      },
      reportVotesGivenCountInput: {
        createdAtFilter: {
          since: date,
        },
      },
      reportCommentsGivenCountInput: {
        createdAtFilter: {
          since: date,
        },
      },
    },
  });

  const topUsers: PublicUserData[] =
    data?.topUsers.users.map((user) => ({
      id: user.id,
      username: user.username,
      joinedAt: new Date(user.createdAt),
      comments: user.reportCommentsGivenCount,
      reports: user.reportsFiledCount,
      upvotes: user.reportVotesGivenCount,
      trusted: user.trusted ?? false,
      imageSrc: user.photo?.url,
    })) || [];

  return {
    isLoading: loading,
    topUsers,
    error,
  };
};

export default useTopUsers;
