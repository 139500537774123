import clsx from 'clsx';
import React, { ReactElement } from 'react';
import NextLink from 'next/link';
import { Button, Text } from '@/components';
import { ScamReportCard } from '@/features/scam-report-card';
import { BROWSE_ALL_ROUTE, FILE_REPORT_ROUTE } from '@/types/routes';
import { ScamReport } from '@/types/scam-report';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { PRIVATE_REPORT_DUMMY_DETAILS } from '@/dummy/results';

export type ReportsSectionProps = StyleProps & {
  /**
   * Whether to show loading indicators while the results are being fetched
   * @default false
   */
  isLoading?: boolean;

  /**
   * The title to display in the header
   */
  title: string;

  /**
   * The results to display on this page
   */
  results: ScamReport[];
};

const ROOT = makeRootClassName('ReportsSection');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function ReportsSection(props: ReportsSectionProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  if (!p.isLoading && p.results.length === 0) return <></>;

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        <div className={el`header`}>
          <Text type="h3" className={el`title`}>
            {p.title}
          </Text>
          <div className={el`actions`}>
            <NextLink href={FILE_REPORT_ROUTE}>
              <Button variant="primary" className={el`new-report-button`}>
                Report a scam
              </Button>
            </NextLink>
            <NextLink href={BROWSE_ALL_ROUTE}>
              <Button variant="secondary" className={el`browse-reports-button`}>
                View All
              </Button>
            </NextLink>
          </div>
        </div>

        <div className={el`reports`}>
          {p.results.map((result) => (
            <div key={result.reportId} className={el`card-wrap`}>
              <ScamReportCard
                {...result}
                {...(result.isPrivate ? PRIVATE_REPORT_DUMMY_DETAILS : {})}
                isStacked
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ReportsSection;
