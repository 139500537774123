import clsx from 'clsx';
import { ReactElement } from 'react';
import { Avatar, CustomContentLoader, Link, Text } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import useTopUsers, { SinceOptions } from '@/features/leaderboard-page/hooks/useTopUsers';
import { UserLeaderboardProps } from '@/features/leaderboard-page/components/user-leaderboard/UserLeaderboard';
import { Mobile, Tablet } from '@/utils/responsive';
import { LEADERBOARD_ROUTE, getRouteForProfile } from '@/types/routes';

export type TopContributorsProps = StyleProps & {
  // add props here
  topUsers: UserLeaderboardProps['topUsers'];
  isLoading: boolean;
};


type ContributorProps = {
  username: string;
  position: number;
  imageSrc: string | undefined;
};

function Contributor(
  { username, position, imageSrc }: ContributorProps): ReactElement {
  return (
    <div className={clsx(el`contributor`, `index-${position}`)}>
      <Text isHeavy className={el`position`}>
        {position}.
      </Text>
      <div>
        <Avatar image={imageSrc ?? '/images/avatar.svg'} size="small" />
      </div>
      <Link
        href={getRouteForProfile(username)}
        className={el`username`}
      >
        {username}
      </Link>
    </div>
  );
}

const ROOT = makeRootClassName('TopContributors');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function TopContributors(props: TopContributorsProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        {props.isLoading ? <CustomContentLoader type="badges" /> : <>
          <Text type="h3" className={el`header-main`}>Top Contributors</Text>
          <Text type="body-md" className={el`header-sub`}>Our top members making web3 safer for all.{" "}
            <Link size='medium' href={LEADERBOARD_ROUTE} className={el`leaderboard-link`}>
              View full leaderboard
            </Link></Text>
          <div className={el`contributors`}>
            {(p.topUsers ?? []).map(({ username, imageSrc }, i) =>
              <Contributor
                username={username}
                imageSrc={imageSrc}
                position={i + 1}
                key={i}
              />
            )}
          </div>
        </>}
      </div>
    </div>
  );
}

function TopContributorsContainer(): ReactElement {
  const { isLoading, topUsers, error } = useTopUsers({
    since: SinceOptions.ALL_TIME,
    topUsersCount: 16,
  });

  if (error) {
    return <></>;
  }

  return (
    <>
      <Mobile>
        <TopContributors
          topUsers={topUsers.slice(0, 10)}
          isLoading={isLoading}
        />
      </Mobile>
      <Tablet>
        <TopContributors
          topUsers={topUsers}
          isLoading={isLoading}
        />
      </Tablet>
    </>
  );
}

export default TopContributorsContainer;

