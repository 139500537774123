import clsx from 'clsx';
import React, { ReactElement } from 'react';
import NextImage from 'next/image';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { IMAGES_PARTNERS } from '@/constants';

export type PartnersInfiniteScrollProps = StyleProps & {
  // add props here
};

const ROOT = makeRootClassName('PartnersInfiniteScroll');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

const LOGOS = [
  { src: `${IMAGES_PARTNERS}/trm.svg`, width: 69, height: 21, alt: 'TRM logo' },
  {
    src: `${IMAGES_PARTNERS}/aave.svg`,
    width: 66,
    height: 17,
    alt: 'Aave logo',
  },
  {
    src: `${IMAGES_PARTNERS}/binanceus-white.svg`,
    width: 146,
    height: 23,
    alt: 'Binanace logo',
  },
  {
    src: `${IMAGES_PARTNERS}/circle.svg`,
    width: 106,
    height: 27,
    alt: 'Circle logo',
  },
  {
    src: `${IMAGES_PARTNERS}/civic.svg`,
    width: 88,
    height: 31,
    alt: 'Civic logo',
  },
  {
    src: `${IMAGES_PARTNERS}/hedera.svg`,
    width: 116,
    height: 35,
    alt: 'Hedera logo',
  },
  {
    src: `${IMAGES_PARTNERS}/opensea.svg`,
    width: 116,
    height: 31,
    alt: 'Opensea logo',
  },
  {
    src: `${IMAGES_PARTNERS}/ransomwhere.svg`,
    width: 160,
    height: 27,
    alt: 'Ransomwhe.re logo',
  },
  {
    src: `${IMAGES_PARTNERS}/solana.svg`,
    width: 115,
    height: 14,
    alt: 'Solana logo',
  },
  {
    src: `${IMAGES_PARTNERS}/ledger.svg`,
    width: 80,
    height: 27,
    alt: 'Ledger logo',
  },
  {
    src: `${IMAGES_PARTNERS}/1inch.svg`,
    width: 42,
    height: 39,
    alt: '1inch logo',
  },
];

function PartnersInfiniteScroll(
  props: PartnersInfiniteScrollProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const partners = (
    <div className={el`partners-wrapper`}>
      <div className={el`slide-1`}>
        {LOGOS.map((logo, j) => (
          <NextImage
            key={j}
            src={logo.src}
            width={logo.width}
            height={logo.height}
            alt={logo.alt}
          />
        ))}
      </div>
      <div className={el`slide-2`}>
        {LOGOS.map((logo, j) => (
          <NextImage
            key={j}
            src={logo.src}
            width={logo.width}
            height={logo.height}
            alt={logo.alt}
          />
        ))}
      </div>
    </div>
  );

  return <div className={clsx(ROOT, p.className)}>{partners}</div>;
}

export default PartnersInfiniteScroll;
