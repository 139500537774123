export const circleSvg = <svg width="280" height="146" viewBox="0 0 280 146" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1809_6029" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="280" height="146">
    <rect x="0.964844" width="279" height="146" fill="#161E26" />
  </mask>
  <g mask="url(#mask0_1809_6029)">
    <path d="M203.73 228.75C278.73 228.75 339.527 169.495 339.527 96.359C339.527 23.2225 278.73 -36 203.73 -36C128.73 -36 67.9648 23.2547 67.9648 96.359C67.9648 169.463 128.762 228.75 203.762 228.75H203.73Z" stroke="#00DFAF" strokeWidth="0.865498" strokeMiterlimit="10" />
    <path d="M178.415 53.792C185.517 49.7431 193.583 47.5259 202.066 47.5259C202.291 47.5259 202.484 47.5259 202.709 47.5259C213.216 47.6544 223.242 51.0927 231.693 57.2946L256.886 32.1017C241.719 19.4731 222.921 12.468 203.158 12.2109C184.746 11.9217 167.265 17.5772 152.869 28.2778C159.617 35.0259 170.029 45.4372 178.415 53.8241V53.792Z" fill="#00DFAF" />
    <path d="M268.261 43.4771L243.068 68.6699C254.669 84.6083 255.89 105.559 246.571 121.948L272.117 147.494C294.739 116.806 293.358 73.6507 268.293 43.4771H268.261Z" fill="#00DFAF" />
    <path d="M164.501 124.486C158.331 116.035 154.861 106.01 154.732 95.5018C154.572 83.7729 158.556 72.7832 165.979 64.1071L140.915 39.0427C126.744 54.5312 119.128 74.6148 119.385 95.9516C119.642 115.746 126.647 134.544 139.276 149.679L164.469 124.486H164.501Z" fill="#00DFAF" />
    <path d="M236.257 134.352C219.29 148.78 194.226 149.165 175.877 135.83C166.976 144.731 156.597 155.11 150.685 161.023C166.591 174.23 186.096 180.881 205.505 180.881C225.621 180.881 245.64 173.716 261.321 159.416L236.257 134.352V134.352Z" fill="#00DFAF" />
  </g>
</svg >;

export const lockerSvg = <svg width="281" height="146" viewBox="0 0 281 146" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1809_6064" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="281" height="146">
    <rect x="0.0351562" width="280" height="146" rx="4.52381" fill="url(#paint0_linear_1809_6064)" />
  </mask>
  <g mask="url(#mask0_1809_6064)">
    <g clipPath="url(#clip0_1809_6064)">
      <path d="M94.0169 -46.4273C134.909 -46.4273 168.18 -13.1567 168.18 27.6988V57.4519H174.519V27.6988C174.519 -16.6743 138.427 -52.803 94.0169 -52.803C49.6072 -52.803 13.5518 -16.6743 13.5518 27.6988V57.9283H19.8908V27.6988C19.8908 -13.1933 53.1614 -46.4273 94.0169 -46.4273Z" fill="#FF1ABE" />
      <path d="M115.598 133.044C115.598 121.172 105.925 111.498 94.0531 111.498C82.1812 111.498 72.5078 121.172 72.5078 133.044C72.5078 140.519 76.3918 147.371 82.8408 151.365L86.3217 153.526V181.814C86.3217 186.064 89.7661 189.545 94.0531 189.545C98.3402 189.545 101.785 186.101 101.785 181.814V153.526L105.265 151.365C111.751 147.371 115.598 140.555 115.598 133.044Z" fill="#FF1ABE" />
      <path d="M196.834 65.145H-8.79978C-15.0529 65.145 -20.1221 70.2142 -20.1221 76.4673V226.368C-20.1221 232.622 -15.0529 237.691 -8.79978 237.691H196.834C203.087 237.691 208.156 232.622 208.156 226.368V76.4673C208.156 70.2142 203.087 65.145 196.834 65.145Z" stroke="#FF1ABE" strokeMiterlimit="10" />
      <path d="M144.033 64.7793V27.6979C144.033 0.143348 121.608 -22.2814 94.0535 -22.2814C66.4989 -22.2814 44.0742 0.143348 44.0742 27.6979V65.2557" stroke="#FF1ABE" strokeWidth="1.03" strokeMiterlimit="10" />
      <path d="M6.22363 65.2557V27.6979C6.22363 -20.7058 45.6135 -60.0956 94.0172 -60.0956C142.421 -60.0956 181.847 -20.7058 181.847 27.7346V64.816" stroke="#FF1ABE" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M122.927 133.043C122.927 117.104 109.992 104.169 94.0533 104.169C78.1142 104.169 65.1797 117.104 65.1797 133.043C65.1797 143.449 70.7492 152.5 79.0303 157.593V181.776C79.0303 190.057 85.7723 196.836 94.09 196.836C102.408 196.836 109.15 190.094 109.15 181.776V157.593C117.431 152.5 123 143.449 123 133.043H122.927Z" stroke="#FF1ABE" strokeWidth="1.03" strokeMiterlimit="10" />
    </g>
  </g>
  <defs>
    <linearGradient id="paint0_linear_1809_6064" x1="140.035" y1="0" x2="140.035" y2="146" gradientUnits="userSpaceOnUse">
      <stop stopColor="#061621" />
      <stop offset="1" stopColor="#082032" />
    </linearGradient>
    <clipPath id="clip0_1809_6064">
      <rect width="230" height="300.499" fill="white" transform="translate(-20.9648 -62)" />
    </clipPath>
  </defs>
</svg>;

export const shieldSvg = <svg width="281" height="146" viewBox="0 0 281 146" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1809_6049" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="281" height="146">
    <rect x="0.106445" width="280" height="146" fill="#161E26" />
  </mask>
  <g mask="url(#mask0_1809_6049)">
    <path d="M195.106 -70.8383V136.362L88.1064 187.347L-18.8936 136.362V-70.8383H195.106V-70.8383Z" stroke="#00D1FF" strokeWidth="0.865498" strokeMiterlimit="10" />
    <path d="M95.1853 -27.4298H88.1058H22.4961V49.5754V62.9582V105.684L88.1058 136.951L153.715 105.684V49.5754V46.6256V-27.4298H95.1853Z" stroke="#00D1FF" strokeWidth="0.865498" strokeMiterlimit="10" />
    <g filter="url(#filter0_d_1809_6049)">
      <path d="M88.1064 136.92L153.716 105.683V49.5748H88.1064V136.92Z" fill="#00D1FF" />
      <path d="M88.1058 -27.4298H22.4961V49.5754H88.1058V-27.4298Z" fill="#00D1FF" />
    </g>
  </g>
  <defs>
    <filter id="filter0_d_1809_6049" x="19.138" y="-30.7879" width="137.936" height="171.066" filterUnits="userSpaceOnUse" colorInterpolation-filters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset />
      <feGaussianBlur stdDeviation="1.67907" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1809_6049" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1809_6049" result="shape" />
    </filter>
  </defs>
</svg>;
