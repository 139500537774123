import React, { ReactElement } from 'react';
import { getSiteLayout, HomePage } from '@/features';
import { ChainsDocument, ScamCategoriesDocument } from '@/generated/graphql';
import { PageType } from '@/types/page';
import { initializeApollo } from '@/utils/apollo';

export default function Index(): React.ReactNode {
  return <HomePage />;
}

export async function getStaticProps() {
  const apolloClient = initializeApollo();

  await Promise.all([
    apolloClient.query({
      query: ChainsDocument,
      variables: {
        input: {},
      },
    }),
    apolloClient.query({
      query: ScamCategoriesDocument,
      variables: {
        input: {},
      },
    }),
  ]);

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
    },
    revalidate: 1,
  };
}

Index.getLayout = (page: ReactElement) => getSiteLayout(page, PageType.HOME);
