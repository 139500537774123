import clsx from 'clsx';
import NextLink from 'next/link';
import { ReactElement } from 'react';
import { Icon, Button, ResponsiveText, Text } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import type { IconData } from '@/components/icon';
import {
  FAQ_ROUTE,
  FILE_REPORT_ROUTE,
  PARTNER_CONTACT_SUPPORT_ROUTE,
} from '@/types/routes';
import { circleSvg, lockerSvg, shieldSvg } from './svgs';

export type WhyReportOnChainabuseProps = StyleProps & {
  // add props here
};

const ROOT = makeRootClassName('WhyReportOnChainabuse');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

type ItemProps = {
  icon: IconData;
  isUpIconPosition: boolean;
  title: string;
  description: string;
  additionalInfo?: string;
  additionalLearnMoreLink?: string;
  linkText: string;
  linkUrl: string;
};

function Item(props: ItemProps): ReactElement {
  return (
    <div className={el`item-wrapper`}>
      <div className={el`item`}>
        {props.isUpIconPosition && (
          <div className={el`icon-wrap`}>
            <Icon
              content={props.icon}
              viewBoxWidth={280}
              viewBoxHeight={146}
              className={el`icon`}
              size="custom"
            />
            <div className={el`square`}></div>
          </div>
        )}
        <div className={el`item-description`}>
          <ResponsiveText type="custom" className={el`item-title`}>
            {props.title}
          </ResponsiveText>
          <ResponsiveText type="body-md" className={el`item-text`}>
            {props.description}
          </ResponsiveText>

          {props.additionalInfo && (
            <ResponsiveText type="custom" className={el`item-additionalInfo`}>
              {props.additionalInfo}{' '}
              {props.additionalLearnMoreLink && (
                <NextLink href={props.additionalLearnMoreLink} passHref>
                  <a className={el`learn-more-link`}>
                    Learn more.
                  </a>
              </NextLink>
              )}
            </ResponsiveText>
          )}

          <NextLink href={props.linkUrl} passHref>
            <Button variant="text-blue" className={el`item-link`}>
              {props.linkText}
            </Button>
          </NextLink>
        </div>
        {!props.isUpIconPosition && (
          <div className={el`icon-wrap`}>
            <Icon
              content={props.icon}
              viewBoxWidth={280}
              viewBoxHeight={146}
              className={el`icon`}
              size="custom"
            />
            <div className={el`square`}></div>
          </div>
        )}
      </div>
    </div>
  );
}

export function WhyReportOnChainabuse(
  props: WhyReportOnChainabuseProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <section className={clsx(ROOT, p.className)} id="why-report-on-chainabuse">
      <div className={el`container`}>
        <Text type="h4" className={el`title`}>
          Why report on Chainabuse?
        </Text>
        <div className={el`content`}>
          <Item
            icon={circleSvg}
            isUpIconPosition={true}
            title="Get free support in a few clicks"
            description="Report your case, opt-in for free personalized advice and support, including immediate actions and next steps."
            linkText="FILE A COMPLAINT"
            linkUrl={FILE_REPORT_ROUTE}
          />
          <Item
            icon={shieldSvg}
            isUpIconPosition={false}
            title="Improve recovery chances"
            description="Chainabuse helps Law Enforcement across multiple jurisdictions to link multiple reports of the same scam, improving funds recovery chances."
            linkText="REPORT YOUR CASE"
            linkUrl={FILE_REPORT_ROUTE}
          />
          <Item
            icon={lockerSvg}
            isUpIconPosition={true}
            title="Make the space safer"
            description="Chainabuse partners with top organizations to enhance users’ safety, allows everyone to check addresses and URLs and helps Law Enforcement accelerate investigations."
            linkText="BECOME A PARTNER"
            linkUrl={PARTNER_CONTACT_SUPPORT_ROUTE}
          />
        </div>
      </div>
    </section>
  );
}
export default WhyReportOnChainabuse;
