import clsx from 'clsx';
import { ReactElement, useEffect, useState } from 'react';
import NextLink from 'next/link';
import NextImage from 'next/image';
import { Button, ResponsiveText, Text } from '@/components';
import { useAuthRequiredAction } from '@/hooks';
import { FILE_REPORT_ROUTE } from '@/types/routes';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import useWindowSize from '../../hooks/useWindowSize';
import { PartnersInfiniteScroll } from './components/partners-infinite-scroll';

export type BannerProps = StyleProps;

const ROOT = makeRootClassName('HomeBanner');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;
const NETWORK_SVG = 'images/network.svg';

export default function Banner(props: BannerProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [isBgPosTop, setIsBgPosTop] = useState(true);
  const [bgPosX, setBgPosX] = useState(0);
  const { isLoggedIn, openAuthModal } = useAuthRequiredAction();

  const useUrlForReportAScam =
    !isLoggedIn && !process.env.NEXT_PUBLIC_REQUIRE_AUTH_TO_REPORT_ENABLED
      ? true
      : false;

  const handleNavigateToFileReport = () => {
    if (useUrlForReportAScam) {
      return openAuthModal();
    }
  };

  const reportAScamHref = !useUrlForReportAScam
    ? FILE_REPORT_ROUTE
    : { href: undefined };

  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width < 1280 && windowSize.width > 640) {
      setBgPosX((1280 - windowSize.width) / 2);
    }
    if (windowSize.width < 640) {
      setIsBgPosTop(false);
      setBgPosX(590 - windowSize.width);
    } else {
      setIsBgPosTop(true);
    }
  }, [windowSize.width]);

  return (
    <>
      <section className={clsx(ROOT, p.className)}>
        <div
          className={el`content`}
          style={{
            backgroundImage: `url('${NETWORK_SVG}')`,
            backgroundPosition: `right ${isBgPosTop ? 'top' : 'bottom'}`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: `calc(100% + ${bgPosX}px`,
            backgroundPositionY: `${isBgPosTop ? 'auto' : `calc(100% + 357px)`
              }`,
          }}
        >
          <div className={el`cta`}>
            <div className={el`info`}>
              <ResponsiveText
                type="h1"
                tabletType="h0"
                className={el`headline`}
              >
                Report malicious crypto activity
              </ResponsiveText>
              <Text type="body-lg" className={el`description`}>
                Report your case, maximise your chances of recovery while making
                web3 safer for all.
              </Text>
              <div className={el`buttons`}>
                <NextLink href={reportAScamHref}>
                  <Button onPress={handleNavigateToFileReport}>
                    Report your case
                  </Button>
                </NextLink>
                <NextLink href="#why-report-on-chainabuse">
                  <Button variant="secondary">Learn more</Button>
                </NextLink>
              </div>
            </div>
          </div>
          <div className={el`svg-box`}>
            <NextImage
              src="/images/learn-more-hand.svg"
              width={376}
              height={376}
              alt="learn more image"
            />
          </div>
        </div>
        <ResponsiveText isHeavy type="body-sm" tabletType='body-lg' tabletIsHeavy className={el`supported-by`}>
          Supported by leading web3 organizations
        </ResponsiveText>
        <PartnersInfiniteScroll />
      </section>
    </>
  );
}
