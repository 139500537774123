import clsx from 'clsx';
import { ReactElement } from 'react';
import { Text } from '@/components';
import {
  formatStatsCount,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { useStats } from '../../hooks';

export type StatsSectionProps = StyleProps & {
  scamReports: number;
  scamCategories: number;
  supportedChains: number;
  reportedAddresses: number;
  reportedUrls: number;
};

const ROOT = makeRootClassName('StatsSection');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

type ItemProps = {
  count: number;
  field: string;
};

function Item(props: ItemProps): ReactElement {
  return (
    <div className={el`item`}>
      <Text type="custom" className={el`count`}>
        {formatStatsCount(props.count)}
      </Text>
      <Text type="custom" className={el`field`}>
        {props.field}
      </Text>
    </div>
  );
}

function StatsSection(props: StatsSectionProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Item count={p.scamReports} field={'Scam reports'} />
      <Item count={p.scamCategories} field={'Scam Categories'} />
      <Item count={p.supportedChains} field={'Supported Chains'} />
      <Item count={p.reportedAddresses} field={'Reported Addresses'} />
      <Item count={p.reportedUrls} field={'Reported URLs'} />
    </div>
  );
}

function StatsSectionContainer(props: StyleProps): ReactElement {
  const {
    scamReports,
    scamCategories,
    supportedChains,
    reportedAddresses,
    reportedUrls,
  } = useStats();

  return (
    <StatsSection
      scamReports={scamReports}
      scamCategories={scamCategories}
      supportedChains={supportedChains}
      reportedAddresses={reportedAddresses}
      reportedUrls={reportedUrls}
    />
  );
}

export default StatsSectionContainer;
