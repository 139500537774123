import clsx from 'clsx';
import { ReactElement } from 'react';
import { Icon, Text } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { blueTick } from '@/assets/icons';

export type PrivacySafetyGuaranteedProps = StyleProps & {
  // add props here
};

const ROOT = makeRootClassName('PrivacySafetyGuaranteed');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

type ItemProps = {
  text: string;
};

function Item(props: ItemProps): ReactElement {
  return (
    <div className={el`item`}>
      <Icon content={blueTick} className={el`icon`} viewBoxHeight={26} viewBoxWidth={26} size='custom' />
      <Text type="body-lg">
        {props.text}
      </Text>
    </div>
  );
}

export function PrivacySafetyGuaranteed(
  props: PrivacySafetyGuaranteedProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`title-wrapper`}>
        <Text className={el`title`} type='h1'>Your privacy and safety, guaranteed.</Text>
      </div>
      <div className={el`items-wrapper`}>
        <Item text='Private reporting enabled to protect of sensitive cases information' />
        <Item text='Personal information is kept private and only shared with Law Enforcement under explicit consent' />
        <Item text='Spam detection system keeps Chainabuse safe for everyone' />
      </div>
    </div>
  );
}
export default PrivacySafetyGuaranteed;